import React, { SVGProps } from "react";

function Github(props: SVGProps<SVGSVGElement>) {
  return (
    <svg height={60} width={60} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M25.425 47.517c0-.45-.016-1.64-.025-3.22-5.135 1.143-6.218-2.538-6.218-2.538-.84-2.187-2.05-2.77-2.05-2.77-1.677-1.174.126-1.15.126-1.15 1.853.134 2.828 1.951 2.828 1.951 1.647 2.893 4.32 2.058 5.373 1.574.168-1.224.645-2.058 1.172-2.531-4.1-.478-8.41-2.102-8.41-9.356 0-2.066.72-3.756 1.901-5.08-.189-.478-.824-2.402.182-5.009 0 0 1.55-.509 5.076 1.94a17.275 17.275 0 014.623-.637c1.567.007 3.146.218 4.622.638 3.523-2.45 5.071-1.94 5.071-1.94 1.008 2.604.374 4.529.184 5.009 1.183 1.323 1.897 3.013 1.897 5.08 0 7.271-4.317 8.871-8.43 9.34.664.584 1.254 1.74 1.254 3.506 0 2.53-.024 4.572-.024 5.193 0 .507.333 1.095 1.27.91 7.33-2.508 12.615-9.599 12.615-17.959 0-10.455-8.267-18.93-18.463-18.93-10.194 0-18.46 8.475-18.46 18.93 0 8.363 5.29 15.458 12.626 17.961.923.173 1.26-.411 1.26-.912z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Github;
