import React, { SVGProps } from "react";

function Twitter(props: SVGProps<SVGSVGElement>) {
  return (
    <svg height={60} width={60} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          d="M41.052 18.437c-1.209-1.375-2.931-2.25-4.838-2.282-3.66-.061-6.628 3.032-6.628 6.908 0 .55.058 1.087.171 1.602-5.508-.358-10.393-3.226-13.662-7.55a7.332 7.332 0 00-.897 3.544c0 2.456 1.17 4.636 2.949 5.922a6.336 6.336 0 01-3.003-.916v.089c0 3.43 2.285 6.307 5.317 6.977a6.242 6.242 0 01-2.993.1c.843 2.813 3.29 4.866 6.191 4.934-2.268 1.883-5.126 3.004-8.232 2.998a12.627 12.627 0 01-1.58-.102c2.933 2.012 6.417 3.184 10.16 3.185 12.192.003 18.859-10.606 18.859-19.81 0-.301-.007-.601-.02-.9a13.634 13.634 0 003.308-3.557 12.65 12.65 0 01-3.807 1.041 6.797 6.797 0 002.914-3.806c-1.28.776-2.699 1.334-4.209 1.623z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
}

export default Twitter;
